import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { motion, LayoutGroup, AnimatePresence } from "framer-motion"
// import slugify from "slugify"

import "../assets/css/project.scss"

import Project from "../components/Projects/ProjectDetail"
import Seo from "../components/Seo"
import Header from "../components/Navbar"
import Banner from "../components/Banner"
import Loader from "../components/Loader"
import { compareAsc } from "date-fns"

const query = graphql`
  {
    allFile(
      filter: {
        relativeDirectory: { eq: "projects" }
        extension: { eq: "svg" }
      }
      sort: { name: ASC }
    ) {
      nodes {
        id
        name
        relativePath
      }
    }

    projects: allContentfulProjects(sort: { dateStart: DESC }) {
      nodes {
        id
        title
        description {
          description
        }
        dateStart(formatString: "DD-MMMM-yyyy")
        dateFinish(formatString: "DD-MMMM-yyyy")
        published
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          description
        }
        content {
          tags
          links {
            backend
            frontend
          }
          tools {
            database
            editor
          }
          location
          instructions
        }
      }
    }
  }
`

// graphql only works without svg
// childImageSharp {
//   gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
// }
// graphql with svg
// image {
//     file {
//         url
//     }
// }
// or
// image {
//     gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
//   }

export default function Projects(props) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loading
      ? document.querySelector("body").classList.add("loading")
      : document.querySelector("body").classList.remove("loading")
  }, [loading])

  // THIS IS FOR GATSBY IMAGE
  const data = useStaticQuery(query)
  let allProjects = data.projects.nodes

  let projectContent = []
  allProjects
    ?.sort((a, b) => new Date(b.dateFinish || a.dateStart) - new Date(a.dateFinish || a.dateStart))
    .forEach((project, index) => {
      const pathToImage = getImage(project.image[0])
      // const slug = slugify(project.title, { lower: true })
      const imgAlt = project.image[0]?.description

      projectContent.push(
        <Project
          key={project.title}
          project={project}
          pathToImage={pathToImage}
          imgAlt={imgAlt}
        />
      )
    })

  let logoAfterBanner
  if (!loading) {
    logoAfterBanner = (
      <div className="transition-image final">
        <motion.img
          transition={{ ease: [0.6, 0.01, 0.05, 0.9], duration: 1.2 }}
          src={require("../assets/images/projects/image-2.svg").default}
          layoutId="main-image-1"
          alt="angga hermawan project skills"
        />
      </div>
    )
  }

  return (
    <LayoutGroup>
      <AnimatePresence>
        {loading ? (
          <motion.div key="loader">
            <Loader setLoading={setLoading} />
          </motion.div>
        ) : (
          <>
            <Header />
            <Banner logoAfterBanner={logoAfterBanner} />
            {!loading && (
              <div className="project-loaded">
                  <div className="content-preview-project">
                    {projectContent}
                </div>
              </div>
            )}
          </>
        )}
      </AnimatePresence>
    </LayoutGroup>
  )
}

export const Head = props => (
  <Seo
    title="Projects | Angga Hermawan"
    description={`This is a list of projects that Angga Hermawan been worked on with javascript, react, react-native, vue, typescript, php, html, css/sass. Come and see`}
    pathname={props.location?.pathname}
  />
)
